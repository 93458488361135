import React, { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { Button } from "reactstrap";
import fetchMethodRequest from "../../../config/service";
import DoAutoCompleteField from "../../Form/Fields/DoAutoCompleteField";
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";

const BacklogTasksComponent = (props) => {
    const [selectedVal, setSelectedVal] = useState({});
    const [selectSprint, setSelectSprint] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(true);
    // const [filterCriteria, setFilterCriteria] = useState({ limit: 20, page: 1, criteria: [] });
    // const loginRole = JSON.parse(localStorage.getItem('loginCredentials') || '{}');

    useEffect(() => {
        if (selectSprint && props.selectedRows && props.selectedRows.length > 0) {
            setIsRowSelected(false)
        } else {
            setIsRowSelected(true)
        }
    }, [props.selectedRows, selectSprint]);

    const {
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm();

    const feedbackFields = [
        {
            name: "projectId",
            fieldName: "projectId",
            type: "relateAutoComplete",
            placeholder: "Search Project First",
            label: " ",
            width: "150px",
            id: "projectId",
            required: true,
            searchField: "name",
            fieldType: "relateAutoComplete",
            displayFields: ["ProjectName"],
            controllerName: "Project",
            searchApi: "projects",
            filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
            isMultiple: false
        },
    ];

    const abc = { _id: selectedVal._id, name: selectedVal.name };

    const feedbackFields2 = [
        {
            name: "sprint",
            fieldName: "sprint",
            type: "relateAutoComplete",
            placeholder: "Select Sprint Name",
            label: " ",
            width: "150px",
            id: "sprint",
            required: false,
            searchField: "name",
            fieldType: "relateAutoComplete",
            displayFields: [],
            controllerName: "sprint",
            searchApi: "sprints",
            filterCriteria: { key: "projectId", value: abc, type: "in" },
            filterCriteria2: { key: 'status', value: ['Not Started', 'In Progress'], type: 'in' },
            isMultiple: false,
        },
    ];

    const onClickSave = (formData) => {
        let ids = props.selectedRows.map(task => task._id);
        let payload = {
            "taskIds": ids,
            "sprintName": formData.sprint.name,
            "sprintId": formData.sprint._id,
            "sprintSearch": formData.sprint.name
        }

        if (payload) {
            return fetchMethodRequest('POST', 'tasks/attachTasksToSprint', payload)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        showToasterMessage(response.respMessage, 'success');
                        reset({
                            projectId: '',
                            sprint: ''
                        });
                        props.getData(selectedVal)
                        props.closeSprintField();
                        setIsRowSelected(true)
                        setValue("projectId", selectedVal)
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }).catch((err) => {
                    return err;
                });
        }
    };

    // const onClickClose = () =>{
    //     props.closeSprintField();
    //     reset({
    //         sprint: ''
    //     });
    //     setValue("project",selectedVal)
    //     setSelectSprint(true)
    // }

    return (
        <div className="mt-1 ms-2">
            <div className="d-flex align-items-center flex-wrap baclog-task-selection">
                {feedbackFields.map((item) => (
                    <div className="pb-2 me-3" key={item.name} style={{ flex: '1 1 auto' }}>
                        <Controller
                            name={item.name}
                            control={control}
                            render={({ field, fieldState }) =>
                                item.type === 'relateAutoComplete' && (
                                    <DoAutoCompleteField
                                        markReq={item.markReq}
                                        input={field}
                                        id={item.id}
                                        name={field.name}
                                        field={field}
                                        filterField={item.filterField}
                                        filterValue={item.filterValue}
                                        filterType={item.isNotEq}
                                        multiple={item.isMultiple}
                                        fieldState={fieldState}
                                        errors={errors}
                                        searchApi={item.searchApi}
                                        searchField={item.searchField}
                                        criteria={item.filterCriteria || null}
                                        filterFieldType={item.filterFieldType || null}
                                        placeholder={item.placeholder}
                                        label={item.label}
                                        item={item}
                                        setValue={setValue}
                                        getValues={getValues}
                                        screen={"Backlogs"}
                                        onChange={(value) => {
                                            props.setSelectedProject(value);
                                            setSelectedVal(value);
                                        }}
                                    />
                                )
                            }
                        />
                    </div>
                ))}

                <form onSubmit={handleSubmit(onClickSave)} style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    {feedbackFields2.map((item) => (
                        <div className="pb-2 me-3" key={item.name} style={{ flex: '1 1 auto' }}>
                            <Controller
                                name={item.name}
                                control={control}
                                render={({ field, fieldState }) =>
                                    item.type === 'relateAutoComplete' && (
                                        <DoAutoCompleteField
                                            markReq={item.markReq}
                                            input={field}
                                            id={item.id}
                                            name={field.name}
                                            field={field}
                                            filterField={item.filterField}
                                            filterValue={item.filterValue}
                                            filterType={item.isNotEq}
                                            multiple={item.isMultiple}
                                            fieldState={fieldState}
                                            errors={errors}
                                            searchApi={item.searchApi}
                                            searchField={item.searchField}
                                            criteria={item.filterCriteria || null}
                                            criteria2={item.filterCriteria2 || null}
                                            filterFieldType={item.filterFieldType || null}
                                            placeholder={item.placeholder}
                                            label={item.label}
                                            item={item}
                                            setValue={setValue}
                                            getValues={getValues}
                                            screen={"Backlogs"}
                                            onChange={(value) => {
                                                if (value.name) {
                                                    setSelectSprint(true);
                                                }
                                            }}
                                        />
                                    )
                                }
                            />
                        </div>
                    ))}

                    <div style={{ paddingTop: '10px' }}>
                        <Button type="submit" color="primary" disabled={isRowSelected}>
                            Move Task
                        </Button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default BacklogTasksComponent;
