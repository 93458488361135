import React from 'react'
import { Col, Row } from 'reactstrap';
import { Paginator } from 'primereact/paginator';

class PaginatorComponent extends React.Component {
    render() {
        const { totalRecords, first, rows, onPageChange, isWeb } = this.props;
        return (
            isWeb ? (
                <Row className="pagination-container mx-0">
                    <Col className="col-12 col-md-12 px-0">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="page-number-indicator me-1">
                                {totalRecords === 0 ? 0 : first + 1} - {(rows + first) >= totalRecords ? totalRecords : rows + first} / {totalRecords}
                            </div>
                            <Paginator
                                totalRecords={totalRecords}
                                first={first}
                                rows={rows}
                                pageLinkSize={3}
                                rowsPerPageOptions={[20, 50, 100]}
                                onPageChange={onPageChange}
                                style={paginatorStyle}
                            />
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col className="col-12 col-md-9 px-0">
                        <Paginator
                            totalRecords={totalRecords}
                            first={first}
                            rows={rows}
                            rowsPerPageOptions={[20, 50, 100]}
                            onPageChange={onPageChange}
                            style={paginatorStyle}
                        />
                    </Col>
                    <Col className="col-12 col-md-3 py-2" style={countColStyle}>
                        <div style={countTextStyle}>
                            TOTAL RECORDS: {totalRecords}
                        </div>
                    </Col>
                </Row>
            )
        );
    }
}

const paginatorStyle = {
    padding: '2px',
    backgroundColor: 'rgba(0,0,0,.03)',
    border: 'none'
};

const countColStyle = {
    backgroundColor: 'white',
    border: 'none',
    textAlign: 'center'
};

const countTextStyle = {
    fontSize: '13px',
    fontFamily: '"Open Sans", "Helvetica Neue", sans-serif',
};

export default PaginatorComponent;